import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY } from 'rxjs';
import { map, exhaustMap, catchError, finalize } from 'rxjs/operators';
import { RolesService } from '../../_services/roles.service';
import { RolesActions } from '../_actions/roles.actions';
import { LayoutActions } from '../_actions/layout.actions';
import { Store, select } from '@ngrx/store';
import { Router } from '@angular/router';
import { AppState } from '../index';
import {
  LayoutUtilsServices,
  MessageType,
} from '../../_services/layout-utils.services';
import {
  currentRolesFilters,
  currentRolesList,
} from '../_selectors/roles.selectors';
// import { selectAuthToken } from '../_selectors/auth.selectors';
// import { LayoutUtilsServices, MessageType } from '../../_services/layout-utils.services';
// import Swal from 'sweetalert2'

@Injectable()
export class RolesEffects {
  loadTabs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RolesActions.loadRoleTabs),
      exhaustMap((action) => {
        // this.store.dispatch(LayoutActions.spinnerTrigger({ show: true }));
        return this._rolesService.findTabsAll().pipe(
          // finalize(() =>
          //   this.store.dispatch(LayoutActions.spinnerTrigger({ show: false }))
          // ),
          map((items) => RolesActions.loadRoleTabsSuccess({ tabs: items })),
          catchError(() => EMPTY)
        );
      })
    )
  );

  loadRoles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RolesActions.loadRoles),
      exhaustMap((action) => {
        this.store.dispatch(LayoutActions.spinnerTrigger({ show: true }));
        return this._rolesService.findAll(action.filters).pipe(
          finalize(() =>
            this.store.dispatch(LayoutActions.spinnerTrigger({ show: false }))
          ),
          map((res) =>
            RolesActions.loadRolesSuccess({ items: res.data, total: res.total })
          ),
          catchError(() => EMPTY)
        );
      })
    )
  );

  loadRole$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RolesActions.loadRole),
      exhaustMap((action) => {
        this.store.dispatch(LayoutActions.spinnerTrigger({ show: true }));
        return this._rolesService.findOne(action.id).pipe(
          finalize(() =>
            this.store.dispatch(LayoutActions.spinnerTrigger({ show: false }))
          ),
          map((data) => RolesActions.loadRoleSuccess({ data })),
          catchError(() => EMPTY)
        );
      })
    )
  );

  addRole$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RolesActions.addRole),
      exhaustMap((action) => {
        this.store.dispatch(LayoutActions.spinnerTrigger({ show: true }));
        return this._rolesService.create(action.data).pipe(
          finalize(() =>
            this.store.dispatch(LayoutActions.spinnerTrigger({ show: false }))
          ),
          map((res) => {
            this._layoutUtilsServices.showNotification({
              message: 'Se ha creado el registro exitosamente',
              type: MessageType.Susses,
            });
            this.router.navigate(['/roles']);
            return RolesActions.addRoleSuccess({ data: res });
          }),
          catchError(() => {
            this._layoutUtilsServices.showNotification({
              message:
                'Error al crear el registro, por favor intente de nuevo',
              type: MessageType.Danger,
            });
            return EMPTY;
          })
        );
      })
    )
  );

  editRole$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RolesActions.editRole),
      exhaustMap((action) => {
        this.store.dispatch(LayoutActions.spinnerTrigger({ show: true }));
        return this._rolesService.update(action.id, action.data).pipe(
          finalize(() =>
            this.store.dispatch(LayoutActions.spinnerTrigger({ show: false }))
          ),
          map((res) => {
            this._layoutUtilsServices.showNotification({
              message: 'Se ha actualizado el registro exitosamente',
              type: MessageType.Susses,
            });
            this.router.navigate(['/roles']);
            return RolesActions.editRoleSuccess({ id: action.id, data: res });
          }),
          catchError(() => {
            this._layoutUtilsServices.showNotification({
              message: 'Error al actualizar el registro, por favor intente de nuevo',
              type: MessageType.Danger,
            });
            return EMPTY;
          })
        );
      })
    )
  );

  removeRole$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RolesActions.removeRole),
      exhaustMap((action) => {
        this.store.dispatch(LayoutActions.spinnerTrigger({ show: true }));
        return this._rolesService.remove(action.id).pipe(
          finalize(() =>
            this.store.dispatch(LayoutActions.spinnerTrigger({ show: false }))
          ),
          map((res) => {
            this._layoutUtilsServices.showNotification({
              message: 'Se ha eliminado el registro exitosamente',
              type: MessageType.Susses,
            });
            this.store.dispatch(
              RolesActions.loadRoles({ filters: this.filters })
            );
            return RolesActions.removeRoleSuccess({ id: action.id });
          }),
          catchError(() => {
            this._layoutUtilsServices.showNotification({
              message:
                'Error al eliminar el registro, por favor intente de nuevo',
              type: MessageType.Danger,
            });
            return EMPTY;
          })
        );
      })
    )
  );

  filters: any = {};
  constructor(
    private actions$: Actions,
    private _rolesService: RolesService,
    private _layoutUtilsServices: LayoutUtilsServices,
    private store: Store<AppState>,
    private router: Router
  ) {
    this.store
      .pipe(select(currentRolesFilters))
      .subscribe((data) => (this.filters = data));
  }
}
