import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'textCodeErrorResponseSales'
})
export class TextCodeErrorResponseSalePipe implements PipeTransform {
  transform(response: any): string {
    switch (response.code) {
      case 'PATENT_ALREADY_REGISTERED':
        return `El registro de la venta falló; esta patente ya está inscrita`;

      case 'VIN_ALREADY_REGISTERED':
        return `El registro de la venta falló, este chasis ya está inscrito`;

      default:
        return `Ha ocurrido un error `;

    }

  }

}
