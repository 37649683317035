import { Inject, Injectable } from '@angular/core';
import { RoleModel, RoleRequestModel } from '../_models/role.model';
import { HttpMethodsService } from './http-methods.services';
import { map } from 'rxjs';


@Injectable({
    providedIn: 'root',
})
export class RankingService {
    constructor(private _httpService: HttpMethodsService) { }

    find(filters: {}) {
        return this._httpService.get('ranking', {}, filters);
    }

}