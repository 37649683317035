import { createReducer, on } from '@ngrx/store';
import { LayoutActions } from '../_actions/layout.actions';
import { VehiclesActions } from '../_actions/vehicles.actions';
import { VehicleModel } from '../../_models/cart.model';
import { SalesModel } from '../../_models/sales.model';

export interface VehiclesState {
    data: SalesModel | null
    status: string | null
}

export const initialStateVehicles: VehiclesState = {
    data: null,
    status: null
};

export const VehiclesFeatureKey = 'vehicles';

export const VehiclesReducer = createReducer(
    initialStateVehicles,

    on(VehiclesActions.addVehicleSuccess, (state, { data, status }) => ({
        ...state,
        status: status
    })),

);