import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY } from 'rxjs';
import { map, exhaustMap, catchError, finalize } from 'rxjs/operators';
import { PrizesService } from '../../_services/prizes.service';
import { PrizesActions } from '../_actions/prizes.actions';
import { LayoutActions } from '../_actions/layout.actions';
import { Store, select } from '@ngrx/store';
import { Router } from '@angular/router';
import { AppState } from '../index';
import {
  LayoutUtilsServices,
  MessageType,
} from '../../_services/layout-utils.services';
import { currentMyPrizesFilters, currentPrizesFilters } from '../_selectors/prizes.selectors';

@Injectable()
export class PrizesEffects {
  loadPrizes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PrizesActions.loadPrizes),
      exhaustMap((action) => {
        this.store.dispatch(LayoutActions.spinnerTrigger({ show: true }));
        return this._prizesService.findAll(action.filters).pipe(
          finalize(() =>
            this.store.dispatch(LayoutActions.spinnerTrigger({ show: false }))
          ),
          map((res) =>
            PrizesActions.loadPrizesSuccess({
              items: res.data,
              total: res.total,
            })
          ),
          catchError(() => EMPTY)
        );
      })
    )
  );

  loadMyPrizes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PrizesActions.loadMyPrizes),
      exhaustMap((action) => {
        this.store.dispatch(LayoutActions.spinnerTrigger({ show: true }));
        return this._prizesService.me(action.filters).pipe(
          finalize(() =>
            this.store.dispatch(LayoutActions.spinnerTrigger({ show: false }))
          ),
          map((res) =>
            PrizesActions.loadMyPrizesSuccess({
              items: res.data,
              total: res.total,
            })
          ),
          catchError(() => EMPTY)
        );
      })
    )
  );

  loadPrize$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PrizesActions.loadPrize),
      exhaustMap((action) => {
        this.store.dispatch(LayoutActions.spinnerTrigger({ show: true }));
        return this._prizesService.findOne(action.id).pipe(
          finalize(() =>
            this.store.dispatch(LayoutActions.spinnerTrigger({ show: false }))
          ),
          map((data) => PrizesActions.loadPrizeSuccess({ data })),
          catchError(() => EMPTY)
        );
      })
    )
  );

  addPrize$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PrizesActions.addPrize),
      exhaustMap((action) => {
        this.store.dispatch(LayoutActions.spinnerTrigger({ show: true }));
        return this._prizesService.create(action.data).pipe(
          finalize(() =>
            this.store.dispatch(LayoutActions.spinnerTrigger({ show: false }))
          ),
          map((res) => {
            this._layoutUtilsServices.showNotification({
              message: 'Se ha creado el registro exitosamente',
              type: MessageType.Susses,
            });
            this.router.navigate(['/prizes']);
            return PrizesActions.addPrizeSuccess({ data: res });
          }),
          catchError(() => {
            this._layoutUtilsServices.showNotification({
              message: 'Error al crear el registro, por favor intente de nuevo',
              type: MessageType.Danger,
            });
            return EMPTY;
          })
        );
      })
    )
  );

  editPrize$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PrizesActions.editPrize),
      exhaustMap((action) => {
        this.store.dispatch(LayoutActions.spinnerTrigger({ show: true }));
        return this._prizesService.update(action.id, action.data).pipe(
          finalize(() =>
            this.store.dispatch(LayoutActions.spinnerTrigger({ show: false }))
          ),
          map((res) => {
            this._layoutUtilsServices.showNotification({
              message: 'Se ha actualizado el registro exitosamente',
              type: MessageType.Susses,
            });
            this.router.navigate(['/prizes']);
            return PrizesActions.editPrizeSuccess({ id: action.id, data: res });
          }),
          catchError(() => {
            this._layoutUtilsServices.showNotification({
              message:
                'Error al actualizar el registro, por favor intente de nuevo',
              type: MessageType.Danger,
            });
            return EMPTY;
          })
        );
      })
    )
  );

  removePrize$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PrizesActions.removePrize),
      exhaustMap((action) => {
        this.store.dispatch(LayoutActions.spinnerTrigger({ show: true }));
        return this._prizesService.remove(action.id).pipe(
          finalize(() =>
            this.store.dispatch(LayoutActions.spinnerTrigger({ show: false }))
          ),
          map((res) => {
            this._layoutUtilsServices.showNotification({
              message: 'Se ha eliminado el registro exitosamente',
              type: MessageType.Susses,
            });
            this.store.dispatch(
              PrizesActions.loadPrizes({ filters: this.filters })
            );
            return PrizesActions.removePrizeSuccess({ id: action.id });
          }),
          catchError(() => {
            this._layoutUtilsServices.showNotification({
              message:
                'Error al eliminar el registro, por favor intente de nuevo',
              type: MessageType.Danger,
            });
            return EMPTY;
          })
        );
      })
    )
  );

  filters: any = {};
  myfilters: any = {};
  constructor(
    private actions$: Actions,
    private _prizesService: PrizesService,
    private _layoutUtilsServices: LayoutUtilsServices,
    private store: Store<AppState>,
    private router: Router
  ) {
    this.store
      .pipe(select(currentPrizesFilters))
      .subscribe((data) => (this.filters = data));

    this.store
      . pipe(select(currentMyPrizesFilters))
      .subscribe((data) => (this.myfilters = data));
  }
}
