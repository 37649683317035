import { createReducer, on } from '@ngrx/store';
import { RolesActions } from '../_actions/roles.actions';
import { RoleModel, TabModel } from '../../_models/role.model';

export interface RolesState {
  list: {
    filters: any;
    data: ReadonlyArray<RoleModel>;
    total: number;
  };
  selected: RoleModel | null;
  tabs: TabModel[];
}

export const initialStateRole: RolesState = {
  list: {
    filters: {},
    data: [],
    total: 0,
  },
  selected: null,
  tabs: [],
};

export const rolesFeatureKey = 'roles';

export const rolesReducer = createReducer(
  initialStateRole,
  on(RolesActions.loadRoles, (state, { filters }) => ({
    ...state,
    list: {
      ...state.list,
      filters,
    },
  })),
  on(RolesActions.loadRolesSuccess, (state, { items, total }) => ({
    ...state,
    list: {
      ...state.list,
      data: items,
      total: total,
    },
  })),
  on(RolesActions.loadRoleSuccess, (state, { data }) => ({
    ...state,
    selected: data,
  })),
  on(RolesActions.loadRoleTabsSuccess, (state, { tabs }) => ({
    ...state,
    tabs: tabs.map((tab) => new TabModel(tab)),
  }))
);
