import { Inject, Injectable } from '@angular/core';
import { HttpMethodsService } from './http-methods.services';
import { map } from 'rxjs';


@Injectable({
    providedIn: 'root',
})
export class SalesByServiceService {
    constructor(private _httpService: HttpMethodsService) { }

    find(filters: {}) {
        return this._httpService.get(`sales_by_service`, {}, filters);
    }

}