import { createReducer, on } from '@ngrx/store';
import { AuthActions } from '../_actions/auth.actions';
import { UserModel } from '../../_models/user.model';

export interface AuthState {
  userAuth: UserModel | null;
  token: string | null;
  status: boolean;
  forgotPassword: boolean;
}

export const initialStateVehicle: AuthState = {
  userAuth: null,
  token: null,
  status: false,
  forgotPassword: false
};

export const authFeatureKey = 'auth';

export const authReducer = createReducer(
  initialStateVehicle,
  on(AuthActions.loginAuthSuccess, (state, { token, status }) => ({
    ...state,
    token: token,
    status: status || false,
  })),
  on(AuthActions.logoutAuth, (state) => ({
    ...state,
    token: null,
    status: false,
  })),
  on(AuthActions.loadUserAuthSuccess, (state, { user }) => ({
    ...state,
    userAuth: user,
  })),
  on(AuthActions.forgotAuthSuccess, (state) => ({
    ...state,
    forgotPassword: true
  }))
);
