import { createReducer, on } from '@ngrx/store';
import { UsersActions } from '../_actions/users.actions';
import { UserModel } from '../../_models/user.model';
import { RoleModel } from '../../_models/role.model';

export interface UsersState {
  list: {
    filters: any;
    data: ReadonlyArray<UserModel>;
    total: number;
  };
  selected: UserModel | null;
  roles: RoleModel[];
}

export const initialStateUser: UsersState = {
  list: {
    filters: {},
    data: [],
    total: 0,
  },
  selected: null,
  roles: [],
};

export const usersFeatureKey = 'users';

export const usersReducer = createReducer(
  initialStateUser,
  on(UsersActions.loadUsers, (state, { filters }) => ({
    ...state,
    list: {
      ...state.list,
      filters,
    },
  })),
  on(UsersActions.loadUsersSuccess, (state, { items, total }) => ({
    ...state,
    list: {
      ...state.list,
      data: items,
      total: total,
    },
  })),
  on(UsersActions.loadUserSuccess, (state, { data }) => ({
    ...state,
    selected: data,
  })),
  on(UsersActions.loadUserRolesSuccess, (state, { items }) => ({
    ...state,
    roles: items.map((item) => new RoleModel(item)),
  }))
);
