import { Injectable } from '@angular/core';
import { PrizeModel, PrizeRequestModel } from '../_models/prize.model';
import { HttpMethodsService } from './http-methods.services';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PrizesService {
  constructor(private _httpService: HttpMethodsService) { }

  findOne(prize_id: string) {
    return this._httpService.get(`prize`, { prize_id });
  }
  findAll(filters: any = {}) {
    return this._httpService.get('prizes', {}, filters).pipe(
      map((res: any) => {
        return {
          data: res?.data.map((r: any) => new PrizeModel(r)),
          total: res?.total,
        };
      })
    );
  }

  me(filters: any = {}) {
    return this._httpService.get('my_prizes', {}, filters).pipe(
      map((res: any) => {
        return {
          data: res?.data.map((r: any) => new PrizeModel(r)),
          total: res?.total,
        };
      })
    );
  }
  create(data: PrizeRequestModel) {
    return this._httpService.post('prizes', data);
  }

  update(prize_id: string, data: PrizeRequestModel) {
    return this._httpService.patch('prize', data, { prize_id });
  }

  remove(prize_id: string) {
    return this._httpService.delete('prize', { prize_id });
  }
}
