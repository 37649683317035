import { Injectable } from "@angular/core";
import { HttpMethodsService } from "./http-methods.services";
import { SalesModel } from "../_models/sales.model";

@Injectable({
    providedIn: 'root',
})

export class VehiclesService {
    constructor(private _httpService: HttpMethodsService) { }

    create(data: SalesModel) {
        return this._httpService.post('vehicles', data);
    }

}