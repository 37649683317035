import { createReducer, on } from '@ngrx/store';
import { LayoutActions } from '../_actions/layout.actions';

export interface LayoutState {
  loadingSpinner: boolean;
}

export const initialStateLayout: LayoutState = {
  loadingSpinner: false,
};

export const layoutFeatureKey = 'layout';

export const layoutReducer = createReducer(
  initialStateLayout,
  on(LayoutActions.spinnerTrigger, (state, { show }) => ({
    ...state,
    loadingSpinner: show,
  }))
);
