import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY } from 'rxjs';
import { map, exhaustMap, catchError, finalize } from 'rxjs/operators';
import { ValuesService } from '../../_services/values.service';
import { ValuesActions } from '../_actions/values.actions';
import { LayoutActions } from '../_actions/layout.actions';
import { Store } from '@ngrx/store';
import { AppState } from '../index';

@Injectable()
export class ValuesEffects {
  loadValuesTypesDocuments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ValuesActions.loadValuesTypesDocuments),
      exhaustMap(() => {
        return this._valuesService
          .findAll({ table_code: 'DOCUMENT_TYPE' })
          .pipe(
            map((res) =>
              ValuesActions.loadValuesTypesDocumentsSuccess({
                items: res,
              })
            ),
            catchError(() => EMPTY)
          );
      })
    )
  );

  loadValuesUbigeos$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ValuesActions.loadValuesUbigeos),
      exhaustMap(() => {
        return this._valuesService.findAll({ table_code: 'UBIGEO' }).pipe(
          map((res) =>
            ValuesActions.loadValuesUbigeosSuccess({
              items: res,
            })
          ),
          catchError(() => EMPTY)
        );
      })
    )
  );

  loadValuesBrands$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ValuesActions.loadValuesBrands),
      exhaustMap(() => {
        return this._valuesService.findAll({ table_code: 'BRAND' }).pipe(
          map((res) =>
            ValuesActions.loadValuesBrandsSuccess({
              items: res,
            })
          ),
          catchError(() => EMPTY)
        );
      })
    )
  );

  loadValuesSalesChannel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ValuesActions.loadValuesSalesChannel),
      exhaustMap(() => {
        return this._valuesService.findAll({ table_code: 'SALES_CHANNEL' }).pipe(
          map((res) =>
            ValuesActions.loadValuesSalesChannelSuccess({
              items: res,
            })
          ),
          catchError(() => EMPTY)
        );
      })
    )
  );

  loadValuesInsuranceCompanies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ValuesActions.loadValuesInsuranceCompanies),
      exhaustMap(() => {
        return this._valuesService.findAll({ table_code: 'INSURANCE_COMPANIES' }).pipe(
          map((res) =>
            ValuesActions.loadValuesInsuranceCompaniesSuccess({
              items: res,
            })
          ),
          catchError(() => EMPTY)
        );
      })
    )
  );

  loadValuesInvoiceType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ValuesActions.loadValuesInvoiceType),
      exhaustMap(() => {
        return this._valuesService.findAll({ table_code: 'INVOICE_TYPE' }).pipe(
          map((res) =>
            ValuesActions.loadValuesInvoiceTypeSuccess({
              items: res,
            })
          ),
          catchError(() => EMPTY)
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private _valuesService: ValuesService
  ) { }
}
