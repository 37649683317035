import { isDevMode } from '@angular/core';
import {
  ActionReducer,
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
  MetaReducer,
} from '@ngrx/store';
import { rolesReducer, RolesState } from './_reducers/roles.reducer';
import { authReducer, AuthState } from './_reducers/auth.reducer';
import { layoutReducer, LayoutState } from './_reducers/layout.reducer';
import { usersReducer, UsersState } from './_reducers/users.reducer';
import { servicesReducer, ServicesState } from './_reducers/services.reducer';
import { prizesReducer, PrizesState } from './_reducers/prizes.reducer';
import { valuesReducer, ValuesState } from './_reducers/values.reducer';
import { brandsReducer, BrandsState } from './_reducers/brands.reducer';
import { VehiclesReducer, VehiclesState } from './_reducers/vehicles.reducer';
import { salesSumaryReducer, SalesSumaryState } from './_reducers/sales-sumary.reducer';
import { SalesByServiceReducer, SalesByServiceState } from './_reducers/sales-by-service.reducer';
import { RankingReducer, RankingState } from './_reducers/ranking.reducer';
import { employeesReducer, EmployeesState } from './_reducers/employee.reducer';

export interface AppState {
  auth: AuthState;
  roles: RolesState;
  layout: LayoutState;
  users: UsersState;
  services: ServicesState;
  prizes: PrizesState;
  values: ValuesState;
  brands: BrandsState;
  vehicles: VehiclesState;
  salesSumary: SalesSumaryState;
  salesByService: SalesByServiceState
  ranking: RankingState
  employees: EmployeesState

}

export const reducers: ActionReducerMap<AppState> = {
  auth: authReducer,
  roles: rolesReducer,
  layout: layoutReducer,
  users: usersReducer,
  services: servicesReducer,
  prizes: prizesReducer,
  values: valuesReducer,
  brands: brandsReducer,
  vehicles: VehiclesReducer,
  salesSumary: salesSumaryReducer,
  salesByService: SalesByServiceReducer,
  ranking: RankingReducer,
  employees: employeesReducer
};

export const metaReducers: MetaReducer<AppState>[] = isDevMode() ? [] : [];
