import {
  ApplicationConfig,
  provideZoneChangeDetection,
  isDevMode,
  importProvidersFrom,
  LOCALE_ID,
} from '@angular/core';
import {
  provideRouter,
  withComponentInputBinding,
  withInMemoryScrolling,
} from '@angular/router';

import { routes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { provideStore } from '@ngrx/store';
import { provideEffects } from '@ngrx/effects';
import { provideRouterStore } from '@ngrx/router-store';
import { reducers } from './core/_state';
import {
  AuthEffects,
  BrandsEffects,
  PrizesEffects,
  RankingEffects,
  RolesEffects,
  SalesByServiceEffects,
  SalesSumaryEffects,
  ServicesEffects,
  UsersEffects,
  ValuesEffects,
  VehiclesEffects
} from './core/_state/_effects';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import { authInterceptor } from './core/_interceptors/auth.interceptor';
import { errorInterceptor } from './core/_interceptors/error.interceptor';
registerLocaleData(localeEs, 'es');

export const appConfig: ApplicationConfig = {
  providers: [
    provideHttpClient(),
    provideHttpClient(withInterceptors([authInterceptor, errorInterceptor])),
    provideZoneChangeDetection({ eventCoalescing: true }),
    // provideRouter(routes, withComponentInputBinding()),
    provideRouter(
      routes,
      withInMemoryScrolling({
        scrollPositionRestoration: 'top',
      }),
      withComponentInputBinding()
    ),
    provideClientHydration(),
    provideAnimationsAsync(),
    provideStoreDevtools({ maxAge: 25, logOnly: !isDevMode() }),
    provideStore(reducers),
    provideEffects(
      AuthEffects,
      RolesEffects,
      UsersEffects,
      ServicesEffects,
      PrizesEffects,
      ValuesEffects,
      BrandsEffects,
      VehiclesEffects,
      SalesSumaryEffects,
      SalesByServiceEffects,
      RankingEffects
    ),
    provideRouterStore(),
    { provide: LOCALE_ID, useValue: 'es' },
  ],
};
