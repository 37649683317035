import { createReducer, on } from '@ngrx/store';
import { EmployeesActions } from '../_actions/employees.actions';
import { EmployeeModel } from '../../_models/employe.model';

export interface EmployeesState {
    list: {
        filters: any;
        data: ReadonlyArray<EmployeeModel>;
        total: number;
    };
    selected: EmployeeModel | null;
}

export const initialStateEmployee: EmployeesState = {
    list: {
        filters: {},
        data: [],
        total: 0,
    },
    selected: null,
};

export const EmployeesFeatureKey = 'employees';

export const employeesReducer = createReducer(
    initialStateEmployee,
    on(EmployeesActions.loadEmployees, (state, { filters }) => ({
        ...state,
        list: {
            ...state.list,
            filters,
        },
    })),
    on(EmployeesActions.loadEmployeesSuccess, (state, { items, total }) => ({
        ...state,
        list: {
            ...state.list,
            data: items,
            total: total,
        },
    })),
    on(EmployeesActions.loadEmployeeSuccess, (state, { data }) => ({
        ...state,
        selected: data,
    }))
);
