import { Injectable } from '@angular/core';
import { ValueModel } from '../_models/base.model';
import { HttpMethodsService } from './http-methods.services';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ValuesService {
  constructor(private _httpValue: HttpMethodsService) {}
  findAll(filters: any = {}): Observable<ValueModel[]> {
    return this._httpValue.get('values', {}, filters).pipe(
      map((res: any) => {
        return res?.data.map((r: any) => new ValueModel(r));
      })
    );
  }
}
