import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { AppState } from "..";
import { select, Store } from "@ngrx/store";
import { SalesSumaryService } from "../../_services/sales-sumary.service";
import { catchError, EMPTY, exhaustMap, map } from "rxjs";
import { SalesSumaryActions } from "../_actions/sales-sumary.actions";
import { currentSalesSumaryFilters } from "../_selectors/sales-sumary.selectors";
import { SalesByServiceActions } from "../_actions/sales-by-service.actions";
import { SalesByServiceService } from "../../_services/service-sales.service";
import { currentSalesByServiceFilters } from "../_selectors/sales-by-service.selectors";

@Injectable()
export class SalesByServiceEffects {

    loadSalesByService$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SalesByServiceActions.loadSalesByService),
            exhaustMap((action) => {
                return this._salesByServiceService.find(action.filters).pipe(
                    map((res) =>
                        SalesByServiceActions.loadSalesByServiceSuccess({
                            data: res
                        })
                    ),
                    catchError(() => EMPTY)
                );
            })
        )
    );

    filters: any = {};
    constructor(
        private actions$: Actions,
        private _salesByServiceService: SalesByServiceService,
        private store: Store<AppState>,
    ) {
        this.store
            .pipe(select(currentSalesByServiceFilters))
            .subscribe((data) => (this.filters = data));
    }
}

