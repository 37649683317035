import { createReducer, on } from '@ngrx/store';
import { SalesSumaryModel, SumaryPerDayModel } from '../../_models/sales_sumary.model';
import { SalesSumaryActions } from '../_actions/sales-sumary.actions';

export interface SalesSumaryState {
    list: {
        filters: any;
        data: SalesSumaryModel | null;
    };
    listPerDay: {
        filters: any;
        data: ReadonlyArray<SumaryPerDayModel>;
        total: number;
    };
}

export const initialStatePrize: SalesSumaryState = {
    list: {
        filters: {},
        data: null,
    },
    listPerDay: {
        filters: {},
        data: [],
        total: 0
    }
};

export const salesSumaryFeatureKey = 'salesSumary';

export const salesSumaryReducer = createReducer(
    initialStatePrize,
    on(SalesSumaryActions.loadSalesSumary, (state, { filters }) => ({
        ...state,
        list: {
            ...state.list,
            filters,
        },
    })),
    on(SalesSumaryActions.loadSalesSumarySuccess, (state, { data }) => ({
        ...state,
        list: {
            ...state.list,
            data: data,
        },
    })),
    on(SalesSumaryActions.loadSalesSumaryPerDaySuccess, (state, { items, total }) => ({
        ...state,
        listPerDay: {
            ...state.listPerDay,
            data: items,
            total: total
        },
    })),
);
