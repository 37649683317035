import { createReducer, on } from '@ngrx/store';
import { ValuesActions } from '../_actions/values.actions';
import { UserModel } from '../../_models/user.model';
import { ValueModel } from '../../_models/base.model';
import { BrandModel, ColorsModel, DataBrandModel, VersionsModel } from '../../_models/brand.model';
import { BrandsActions } from '../_actions/brands.actions';
import { version } from 'os';

export interface BrandsState {
    list: {
        data: DataBrandModel[];
        total: number;
    }
    selected: DataBrandModel | null;
    versions: VersionsModel[];
    colors: ColorsModel[]

}

export const initialStateBrands: BrandsState = {
    list: {
        data: [],
        total: 0
    },
    selected: null,
    versions: [],
    colors: []

};

export const brandsFeatureKey = 'brands';

export const brandsReducer = createReducer(
    initialStateBrands,
    on(BrandsActions.loadBrandsSuccess, (state, { data, total }) => ({
        ...state,
        list: {
            ...state.list,
            data: data.map((brand) => new DataBrandModel(brand)),
            total: total
        }
    })),

    on(BrandsActions.loadBrandSuccess, (state, { data }) => ({
        ...state,
        selected: data,
    })),

    on(BrandsActions.loadModelSuccess, (state, { data }) => ({
        ...state,
        versions: data.versions.map((version) => new VersionsModel(version)),
        colors: data.colors.map((color) => new VersionsModel(color))
    })),
);