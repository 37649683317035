import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { AppState } from '../_state';
import { Store } from '@ngrx/store';
import { catchError, of, throwError } from 'rxjs';
import { AuthActions } from '../_state/_actions/auth.actions';

export const errorInterceptor: HttpInterceptorFn = (req, next) => {
  const store = inject(Store<AppState>);

  return next(req).pipe(
    catchError((error) => {
      if (error.status === 401) {
        store.dispatch(AuthActions.logoutAuth());
      }
      return throwError(() => error);
    })
  );
};
