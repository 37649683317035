import { Component } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../core/_state';
import { selectLayoutLoadingSpinner } from '../../core/_state/_selectors/layout.selectors';

@Component({
  selector: 'app-spinner',
  standalone: true,
  imports: [],
  templateUrl: './spinner.component.html',
  styleUrl: './spinner.component.css'
})
export class SpinnerComponent {
  show: boolean = false;

  constructor(
    private store: Store<AppState>,
  ) { }

  ngOnInit() {
    this.store.pipe(select(selectLayoutLoadingSpinner)).subscribe((res) => {
      this.show = res;
    });
  }
}
