import { createReducer, on } from '@ngrx/store';
import { SalesSumaryModel } from '../../_models/sales_sumary.model';
import { SalesSumaryActions } from '../_actions/sales-sumary.actions';
import { SalesByServiceModel } from '../../_models/sales_by_service.model';
import { SalesByServiceActions } from '../_actions/sales-by-service.actions';

export interface SalesByServiceState {
    list: {
        filters: any;
        data: ReadonlyArray<SalesByServiceModel>;
    };
}

export const initialStatePrize: SalesByServiceState = {
    list: {
        filters: {},
        data: [],
    },
};

export const salesSumaryFeatureKey = 'salesByService';

export const SalesByServiceReducer = createReducer(
    initialStatePrize,
    on(SalesByServiceActions.loadSalesByService, (state, { filters }) => ({
        ...state,
        list: {
            ...state.list,
            filters,
        },
    })),
    on(SalesByServiceActions.loadSalesByServiceSuccess, (state, { data }) => ({
        ...state,
        list: {
            ...state.list,
            data: data.map((d) => new SalesByServiceModel(d)),
        },
    })),
);
