import { createReducer, on } from '@ngrx/store';
import { RankingActions } from '../_actions/ranking.actions';
import { RankingModel } from '../../_models/ranking.model';

export interface RankingState {
    list: {
        filters: any;
        data: ReadonlyArray<RankingModel>;
        total: number;
    };

}

export const initialStatePrize: RankingState = {
    list: {
        filters: {},
        data: [],
        total: 0,
    },
};

export const RankingFeatureKey = 'ranking';

export const RankingReducer = createReducer(
    initialStatePrize,
    on(RankingActions.loadRanking, (state, { filters }) => ({
        ...state,
        list: {
            ...state.list,
            filters,
        },
    })),
    on(RankingActions.loadRankingSuccess, (state, { items, total }) => ({
        ...state,
        list: {
            ...state.list,
            data: items,
            total: total,
        },
    })),
);
