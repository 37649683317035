import { Injectable } from '@angular/core';
import { HttpMethodsService } from './http-methods.services';
import { map } from 'rxjs';
import { EmployeeModel, EmployeeRequestModel } from '../_models/employe.model';

@Injectable({
    providedIn: 'root',
})
export class EmployeesService {
    constructor(private _httpService: HttpMethodsService) { }

    findOne(id: string) {
        return this._httpService.get(`employee`, { id });
    }
    findAll(filters: any = {}) {
        return this._httpService.get('employees', {}, filters).pipe(
            map((res: any) => {
                return {
                    data: res?.data.map((r: any) => new EmployeeModel(r)),
                    total: res?.total,
                };
            })
        );
    }
    create(data: EmployeeRequestModel) {
        return this._httpService.post('employees', data);
    }

    update(id: string, data: EmployeeRequestModel) {
        return this._httpService.patch('employee', data, { id });
    }

    remove(id: string) {
        return this._httpService.delete('employee', { id });
    }
}
