import { Inject, Injectable } from '@angular/core';
import { RoleModel, RoleRequestModel } from '../_models/role.model';
import { HttpMethodsService } from './http-methods.services';
import { map } from 'rxjs';


@Injectable({
  providedIn: 'root',
})
export class RolesService {
  constructor(private _httpService: HttpMethodsService) {}

  findOne(role_id: string) {
    return this._httpService.get(`role`, { role_id });
  }
  findAll(filters: any = {}) {
    return this._httpService.get('roles', {}, filters).pipe(
      map((res: any) => {
        return {
          data: res?.data.map((r: any) => (new RoleModel(r))),
          total: res?.total
        }
      }));;
  }
  create(data: RoleRequestModel) {
    return this._httpService.post('roles', data);
  }

  update(role_id: string, data: RoleRequestModel) {
    return this._httpService.patch('role', data, { role_id });
  }

  remove(role_id: string) {
    return this._httpService.delete('role', { role_id });
  }

  findTabsAll(filters: any = {}) {
    return this._httpService.get('tabs', {}, filters);
  }
}
