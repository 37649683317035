import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY } from 'rxjs';
import { map, exhaustMap, catchError, finalize } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BrandsService } from '../../_services/brands.service';
import { BrandsActions } from '../_actions/brands.actions';
import { AppState } from '..';
import { Store } from '@ngrx/store';
import { LayoutActions } from '../_actions/layout.actions';

@Injectable()
export class BrandsEffects {

    loadBrands$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BrandsActions.loadBrands),
            exhaustMap((action) => {
                return this._brandsService.findAll(action.filters).pipe(
                    map((res) => {
                        return BrandsActions.loadBrandsSuccess({
                            data: res?.data,
                            total: res?.total
                        })
                    }

                    ),
                    catchError(() => EMPTY)
                );
            })
        )
    );

    loadBrand$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BrandsActions.loadBrand),
            exhaustMap((action) => {
                this.store.dispatch(LayoutActions.spinnerTrigger({ show: true }));
                return this._brandsService.findOne(action.id).pipe(
                    finalize(() =>
                        this.store.dispatch(LayoutActions.spinnerTrigger({ show: false }))
                    ),
                    map((res) => {
                        return BrandsActions.loadBrandSuccess({ data: res })
                    }),
                    catchError(() => EMPTY)
                );
            })
        )
    );

    // loadmodels$ = createEffect(() =>
    //     this.actions$.pipe(
    //         ofType(BrandsActions.loadModel),
    //         exhaustMap((action) => {
    //             this.store.dispatch(LayoutActions.spinnerTrigger({ show: true }));
    //             return this._brandsService.findModels(action.id).pipe(
    //                 finalize(() =>
    //                     this.store.dispatch(LayoutActions.spinnerTrigger({ show: false }))
    //                 ),
    //                 map((res) => {
    //                     console.log(res)
    //                     return BrandsActions.loadModelSuccess({ data: res.data })
    //                 }),
    //                 catchError(() => EMPTY)
    //             );
    //         })
    //     )
    // );

    loadmodel$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BrandsActions.loadModel),
            exhaustMap((action) => {
                this.store.dispatch(LayoutActions.spinnerTrigger({ show: true }));
                return this._brandsService.findModel(action.brand_id, action.id).pipe(
                    finalize(() =>
                        this.store.dispatch(LayoutActions.spinnerTrigger({ show: false }))
                    ),
                    map((res) => {
                        return BrandsActions.loadModelSuccess({ data: res })
                    }),
                    catchError(() => EMPTY)
                );
            })
        )
    );

    constructor(
        private actions$: Actions,
        private _brandsService: BrandsService,
        private store: Store<AppState>,
    ) { }
}
