import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY } from 'rxjs';
import { map, exhaustMap, catchError, finalize } from 'rxjs/operators';
import { LayoutActions } from '../_actions/layout.actions';
import { Store, select } from '@ngrx/store';
import { Router } from '@angular/router';
import { AppState } from '../index';
import {
    LayoutUtilsServices,
    MessageType,
} from '../../_services/layout-utils.services';
import { VehiclesActions } from '../_actions/vehicles.actions';
import { VehiclesService } from '../../_services/vehicle.service';
import { TextCodeErrorResponseSalePipe } from '../../_pipes/text-code-error-response-sales.pipe'



@Injectable()
export class VehiclesEffects {
    textCodeErrorResponseSale = new TextCodeErrorResponseSalePipe()

    addVehicle$ = createEffect(() =>
        this.actions$.pipe(
            ofType(VehiclesActions.addVehicle),
            exhaustMap((action) => {
                this.store.dispatch(LayoutActions.spinnerTrigger({ show: true }));
                return this._vehicleService.create(action.data).pipe(
                    finalize(() =>
                        this.store.dispatch(LayoutActions.spinnerTrigger({ show: false }))
                    ),
                    map((res) => {
                        this._layoutUtilsServices.showNotification({
                            message: 'Se ha registrado la venta exitosamente',
                            type: MessageType.Susses,
                        });
                        // this.router.navigate(['/']);
                        return VehiclesActions.addVehicleSuccess({ data: action.data, status: 'ok' });
                    }),
                    catchError(({ error }) => {
                        console.log(error)
                        this._layoutUtilsServices.showNotification({
                            message:
                                this.textCodeErrorResponseSale.transform(error.response),
                            type: MessageType.Danger,
                        });
                        return EMPTY;
                    })
                );
            })
        )
    );

    constructor(
        private actions$: Actions,
        private _vehicleService: VehiclesService,
        private _layoutUtilsServices: LayoutUtilsServices,
        private store: Store<AppState>,
        private router: Router
    ) {
    }
}