import { Injectable } from '@angular/core';
import { ValueModel } from '../_models/base.model';
import { HttpMethodsService } from './http-methods.services';
import { map, Observable } from 'rxjs';
import { BrandModel, DataBrandModel } from '../_models/brand.model';

@Injectable({
    providedIn: 'root',
})
export class BrandsService {
    constructor(private _httpService: HttpMethodsService) { }

    findOne(id: string) {
        return this._httpService.get('brand', { id });
    }

    findModels(brand_id: string) {
        return this._httpService.get('models', { brand_id });
    }
    findModel(brand_id: string, id: string) {
        return this._httpService.get('model', { brand_id, id });
    }
    findAll(filters: any = {}) {
        return this._httpService.get('brands', {}, filters).pipe(
            map((res: any) => {
                return {
                    data: res?.data.map((r: any) => new DataBrandModel(r)),
                    total: res?.total,
                };
            })
        );
    }
}
