import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { AppState } from "..";
import { select, Store } from "@ngrx/store";
import { SalesSumaryService } from "../../_services/sales-sumary.service";
import { catchError, EMPTY, exhaustMap, finalize, map, of, switchMap } from "rxjs";
import { SalesSumaryActions } from "../_actions/sales-sumary.actions";
import { currentSalesSumaryFilters } from "../_selectors/sales-sumary.selectors";
import { LayoutActions } from "../_actions/layout.actions";
import saveAs from "file-saver";

@Injectable()
export class SalesSumaryEffects {

    loadSalesSumary$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SalesSumaryActions.loadSalesSumary),
            exhaustMap((action) => {
                return this._salesSumaryService.find(action.filters).pipe(
                    map((res) =>
                        SalesSumaryActions.loadSalesSumarySuccess({
                            data: res
                        })
                    ),
                    catchError(() => EMPTY)
                );
            })
        )
    );

    loadSalesSumaryPerDay$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SalesSumaryActions.loadSalesSumaryPerDay),
            exhaustMap((action) => {
                return this._salesSumaryService.find(action.filters).pipe(
                    map((res) =>
                        SalesSumaryActions.loadSalesSumaryPerDaySuccess({
                            items: res?.summaryPerDay.data,
                            total: res?.summaryPerDay.total
                        })
                    ),
                    catchError(() => EMPTY)
                );
            })
        )
    );

    DownloadSalessumary$ = createEffect(() => this.actions$.pipe(
        ofType(SalesSumaryActions.downloadSalesSumary),
        switchMap((action) => {
            return this._salesSumaryService.export(action.filters, 'sales-summary')
        }),
    ), {
        dispatch: false,
    });

    filters: any = {};
    constructor(
        private actions$: Actions,
        private _salesSumaryService: SalesSumaryService,
        private store: Store<AppState>,
    ) {
        this.store
            .pipe(select(currentSalesSumaryFilters))
            .subscribe((data) => (this.filters = data));
    }
}

