import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY } from 'rxjs';
import { map, exhaustMap, catchError, finalize } from 'rxjs/operators';

import { LayoutActions } from '../_actions/layout.actions';
import { Store, select } from '@ngrx/store';
import { Router } from '@angular/router';
import { AppState } from '../index';
import {
    LayoutUtilsServices,
    MessageType,
} from '../../_services/layout-utils.services';

import { EmployeesActions } from '../_actions/employees.actions';
import { currentEmployeesFilters } from '../_selectors/employees.selectors';
import { EmployeesService } from '../../_services/employees.service';

@Injectable()
export class EmployeesEffects {
    loadEmployees$ = createEffect(() =>
        this.actions$.pipe(
            ofType(EmployeesActions.loadEmployees),
            exhaustMap((action) => {
                this.store.dispatch(LayoutActions.spinnerTrigger({ show: true }));
                return this._employeesService.findAll(action.filters).pipe(
                    finalize(() =>
                        this.store.dispatch(LayoutActions.spinnerTrigger({ show: false }))
                    ),
                    map((res) =>
                        EmployeesActions.loadEmployeesSuccess({
                            items: res.data,
                            total: res.total,
                        })
                    ),
                    catchError(() => EMPTY)
                );
            })
        )
    );

    loadEmployee$ = createEffect(() =>
        this.actions$.pipe(
            ofType(EmployeesActions.loadEmployee),
            exhaustMap((action) => {
                this.store.dispatch(LayoutActions.spinnerTrigger({ show: true }));
                return this._employeesService.findOne(action.id).pipe(
                    finalize(() =>
                        this.store.dispatch(LayoutActions.spinnerTrigger({ show: false }))
                    ),
                    map((data) => EmployeesActions.loadEmployeeSuccess({ data })),
                    catchError(() => EMPTY)
                );
            })
        )
    );

    addEmployee$ = createEffect(() =>
        this.actions$.pipe(
            ofType(EmployeesActions.addEmployee),
            exhaustMap((action) => {
                this.store.dispatch(LayoutActions.spinnerTrigger({ show: true }));
                return this._employeesService.create(action.data).pipe(
                    finalize(() =>
                        this.store.dispatch(LayoutActions.spinnerTrigger({ show: false }))
                    ),
                    map((res) => {
                        this._layoutUtilsServices.showNotification({
                            message: 'Se ha creado el registro exitosamente',
                            type: MessageType.Susses,
                        });
                        // this.router.navigate(['/employees', res.id, 'edit']);
                        this.router.navigate(['/employees']);
                        return EmployeesActions.addEmployeeSuccess({ data: res });
                    }),
                    catchError(() => {
                        this._layoutUtilsServices.showNotification({
                            message: 'Error al crear el registro, por favor intente de nuevo',
                            type: MessageType.Danger,
                        });
                        return EMPTY;
                    })
                );
            })
        )
    );

    editEmployee$ = createEffect(() =>
        this.actions$.pipe(
            ofType(EmployeesActions.editEmployee),
            exhaustMap((action) => {
                this.store.dispatch(LayoutActions.spinnerTrigger({ show: true }));
                return this._employeesService.update(action.id, action.data).pipe(
                    finalize(() =>
                        this.store.dispatch(LayoutActions.spinnerTrigger({ show: false }))
                    ),
                    map((res) => {
                        this._layoutUtilsServices.showNotification({
                            message: 'Se ha actualizado el registro exitosamente',
                            type: MessageType.Susses,
                        });
                        this.router.navigate(['/employees']);
                        return EmployeesActions.editEmployeeSuccess({ id: action.id, data: res });
                    }),
                    catchError(() => {
                        this._layoutUtilsServices.showNotification({
                            message:
                                'Error al actualizar el registro, por favor intente de nuevo',
                            type: MessageType.Danger,
                        });
                        return EMPTY;
                    })
                );
            })
        )
    );

    removeEmployee$ = createEffect(() =>
        this.actions$.pipe(
            ofType(EmployeesActions.removeEmployee),
            exhaustMap((action) => {
                this.store.dispatch(LayoutActions.spinnerTrigger({ show: true }));
                return this._employeesService.remove(action.id).pipe(
                    finalize(() =>
                        this.store.dispatch(LayoutActions.spinnerTrigger({ show: false }))
                    ),
                    map((res) => {
                        this._layoutUtilsServices.showNotification({
                            message: 'Se ha eliminado el registro exitosamente',
                            type: MessageType.Susses,
                        });
                        this.store.dispatch(
                            EmployeesActions.loadEmployees({ filters: this.filters })
                        );
                        return EmployeesActions.removeEmployeeSuccess({ id: action.id });
                    }),
                    catchError(() => {
                        this._layoutUtilsServices.showNotification({
                            message:
                                'Error al eliminar el registro, por favor intente de nuevo',
                            type: MessageType.Danger,
                        });
                        return EMPTY;
                    })
                );
            })
        )
    );

    filters: any = {};
    constructor(
        private actions$: Actions,
        private _employeesService: EmployeesService,
        private _layoutUtilsServices: LayoutUtilsServices,
        private store: Store<AppState>,
        private router: Router
    ) {
        this.store
            .pipe(select(currentEmployeesFilters))
            .subscribe((data) => (this.filters = data));
    }
}
