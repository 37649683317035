
import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import {
  LayoutUtilsServices,
  MessageType,
  NotificationData,
} from '../../core/_services/layout-utils.services';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-notification',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './notification.component.html',
  animations: [
    trigger('notificationAnimation', [
      state('void', style({ opacity: 0, transform: 'translateY(100%)' })),
      transition(':enter, :leave', [
        animate('300ms ease-in-out')
      ]),
    ]),
  ],
})
export class NotificationComponent {
  notification: NotificationData | null = null;
  toastClasses: string = '';
  messageType = MessageType;
  constructor(private _layoutUtilsServices: LayoutUtilsServices) {}

  ngOnInit(): void {
    this._layoutUtilsServices.notification$.subscribe((data) => {
      this.notification = null
      this.notification = data;
      if (data) {
        this.toastClasses = this.getToastClasses(data.type);
      }
    });
  }

  getToastClasses(type: MessageType): string {
    switch (type) {
      case MessageType.Susses:
        return 'bg-green-500 text-white';
      case MessageType.Danger:
        return 'bg-red-500 text-white';
      case MessageType.Warning:
        return 'bg-yellow-500 text-white';
      default:
        return '';
    }
  }
}
