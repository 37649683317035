import { createReducer, on } from '@ngrx/store';
import { ServicesActions } from '../_actions/services.actions';
import { SalesPersonModel, ServiceModel } from '../../_models/service.model';
import { ValueModel } from '../../_models/base.model';

export interface ServicesState {
  list: {
    filters: any;
    data: ReadonlyArray<ServiceModel>;
    total: number;
  };
  selected: ServiceModel | null;
  durations: ValueModel[];
  salesPersons: SalesPersonModel[];
}

export const initialStateService: ServicesState = {
  list: {
    filters: {},
    data: [],
    total: 0,
  },
  selected: null,
  durations: [],
  salesPersons: [],
};

export const servicesFeatureKey = 'services';

export const servicesReducer = createReducer(
  initialStateService,
  on(ServicesActions.loadServices, (state, { filters }) => ({
    ...state,
    list: {
      ...state.list,
      filters,
    },
  })),
  on(ServicesActions.loadServicesSuccess, (state, { items, total }) => ({
    ...state,
    list: {
      ...state.list,
      data: items,
      total: total,
    },
  })),
  on(ServicesActions.loadServiceSuccess, (state, { data }) => ({
    ...state,
    selected: data,
  })),
  on(ServicesActions.loadServiceDurationsSuccess, (state, { items }) => ({
    ...state,
    durations: items.map((item) => new ValueModel(item)),
  })),
  on(ServicesActions.loadServiceSalesPersonsSuccess, (state, { items }) => ({
    ...state,
    salesPersons: items.map((item) => new SalesPersonModel(item)),
  }))
);
